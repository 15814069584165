import { ComponentProps } from 'react'

import Text from '../../Text'
import styles from './styles.module.scss'

const Label = ({ className, ...props }: ComponentProps<'label'>) => (
  <Text
    as="label"
    weight="medium"
    size="md"
    {...props}
    color="element-default"
    className={[styles.label, className].join(' ')}
  />
)

export default Label
